import i18next from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";

export const resources = {
  en: {
    common: {
      "notfound.header.title": "Page not found",
      "notfound.header.description":
        "Products may no longer be available. Contact your Sales Advisor directly",

      "wishlist.header.share.title": "YOUR PRODUCT",
      "wishlist.header.title": "YOUR SELECTION",
      "wishlist.header.description":
        "I have selected these {{count}} products for you. Let yourself be seduced by our unique know-how.",

      "wishlist.products.size": "Size",
      "wishlist.products.quantity": "Quantity",
      "wishlist.products.totalAmount": "Total",

      "product.fiels.reference": "Ref",

      "wishlist.footer.sa.title":
        "I remain at your entire disposal if you need any further information. /n Best regards,",
      "wishlist.footer.sa.callme": "Call me",
      "wishlist.footer.sa.callme.withoutphone": "Call me",
      "wishlist.footer.sa.emailme": "Email Me",
      "wishlist.footer.sa.emailme.withoutemail": "Email Me",
      "wishlist.footer.date.shareone": "Shared on {{date}}",
      "wishlist.footer.mentions":
        "* Availability and prices are indicative only and may vary depending notably on the selected Dior boutique. Please contact your Sales Advisor for more information.",
    },
  },
  ru: {
    common: {
      "notfound.header.title": "Page not found",
      "notfound.header.description":
        "Products may no longer be available. Contact your Sales Advisor directly",

      "wishlist.header.share.title": "ВАШЕ ИЗДЕЛИЕ",
      "wishlist.header.title": "ВАША ПОДБОРКА",
      "wishlist.header.description":
        "Мы выбрали эти {{count}} изделия (-й) специально для Вас. Приглашаем Вас оценить уникальное мастерство модного дома.",

      "wishlist.products.size": "Size",
      "wishlist.products.quantity": "Quantity",
      "wishlist.products.totalAmount": "Total",

      "product.fiels.reference": "Ref",

      "wishlist.footer.sa.title":
        "Вы можете обратиться ко мне в любое время, если Вам понадобится какая-либо дополнительная информация. /n  С уважением,",
      "wishlist.footer.sa.callme": "Свяжитесь со мной по телефону",
      "wishlist.footer.sa.callme.withoutphone": "Свяжитесь со мной по телефону",
      "wishlist.footer.sa.emailme": "Свяжитесь со мной по эл. почте",
      "wishlist.footer.sa.emailme.withoutemail":
        "Свяжитесь со мной по эл. почте",
      "wishlist.footer.date.shareone": "Дата отправления {{date}}",
      "wishlist.footer.mentions":
        "*Наличие изделий в продаже и цены являются ориентировочными и могут варьироваться в зависимости от выбранного бутика Dior. Более подробную информацию можно получить у продавца-консультанта.",
    },
  },
  ja: {
    common: {
      "notfound.header.title": "ページが見つかりません",
      "notfound.header.description":
        "製品の在庫がなくなった可能性があります。セールスアドバイザーまで直接お問い合わせください。",

      "wishlist.header.share.title": "ご紹介商品",
      "wishlist.header.title": "お客様への商品セレクション",
      "wishlist.header.description":
        "お客様向けにお選びした商品をご案内いたします。メゾンならではのセレクションをお楽しみください。",

      "wishlist.products.size": "サイズ",
      "wishlist.products.quantity": "数量",
      "wishlist.products.totalAmount": "合計",

      "product.fiels.reference": "リファレンス",

      "wishlist.footer.sa.title":
        "ご不明な点などございましたら、どうぞお気軽にお尋ねください。/n どうぞよろしくお願いいたします。",
      "wishlist.footer.sa.callme": "電話でのお問い合わせ",
      "wishlist.footer.sa.callme.withoutphone": "電話でのお問い合わせ",
      "wishlist.footer.sa.emailme": "Eメールでのお問い合わせ",
      "wishlist.footer.sa.emailme.withoutemail": "Eメールでのお問い合わせ",
      "wishlist.footer.date.shareone": "{{date}} に共有",
      "wishlist.footer.mentions":
        "* 表示された在庫状況と価格は、ディオール ブティックによって異なる場合があります。詳細は、セールスアドバイザーまでお問い合わせください。",
    },
  },
  fr: {
    common: {
      "notfound.header.title": "Page non disponible",
      "notfound.header.description":
        "Les produits peuvent ne plus être disponibles. Contactez directement votre conseiller.",

      "wishlist.header.share.title": "VOTRE PRODUIT",
      "wishlist.header.title": "VOTRE SÉLECTION",
      "wishlist.header.description":
        "J'ai sélectionné ces {{count}} produits pour vous. Laissez-vous séduire par notre savoir-faire unique.",

      "wishlist.products.size": "Taille",
      "wishlist.products.quantity": "Quantité",
      "wishlist.products.totalAmount": "Total",

      "product.fiels.reference": "Ref",

      "wishlist.footer.sa.title":
        "Je reste à votre entière disposition pour toute information complémentaire. /n Cordialement,",
      "wishlist.footer.sa.callme": "Appelez moi",
      "wishlist.footer.sa.callme.withoutphone": "Appelez moi",
      "wishlist.footer.sa.emailme": "Envoyez moi un Email",
      "wishlist.footer.sa.emailme.withoutemail": "Envoyez moi un Email",
      "wishlist.footer.date.shareone": "Partagé le {{date}}",
      "wishlist.footer.mentions":
        "* Les disponibilités et tarifs sont à titre indicatif et peuvent varier notamment en fonction de la boutique Dior sélectionnée. Veuillez contacter votre Conseiller clientèle pour plus d’information.",
    },
  },
  ko: {
    common: {
      "notfound.header.title": "페이지를 찾을 수 없음",
      "notfound.header.description":
        "제품을 더 이상 이용할 수 없습니다. 세일즈 어드바이저에게 직접 문의하세요",

      "wishlist.header.share.title": "내 상품",
      "wishlist.header.title": "나를 위한 셀렉션",
      "wishlist.header.description":
        "고객님을 위해 엄선한 {{count}} 상품을 소개합니다.Dior만의 노하우를 만나보세요.",

      "wishlist.products.size": "사이즈",
      "wishlist.products.quantity": "수량",
      "wishlist.products.totalAmount": "합계",

      "product.fiels.reference": "참고",

      "wishlist.footer.sa.title":
        "더 자세한 정보가 필요한 경우, 언제든지 문의해 주세요. /n 감사합니다.",
      "wishlist.footer.sa.callme": "전화하기",
      "wishlist.footer.sa.callme.withoutphone": "전화하기",
      "wishlist.footer.sa.emailme": "이메일 보내기",
      "wishlist.footer.sa.emailme.withoutemail": "이메일 보내기",
      "wishlist.footer.date.shareone": "{{date}} 에 공유됨",
      "wishlist.footer.mentions":
        "* 이용 가능 여부와 가격은 참고용이며, 선택한 Dior 부티크에 따라 다를 수 있습니다. 자세한 내용은 세일즈 어드바이저에게 문의하시기 바랍니다.",
    },
  },
  zh_TW: {
    common: {
      "notfound.header.title": "找不到頁面",
      "notfound.header.description": "商品已不再供應。請直接聯絡您的銷售顧問",

      "wishlist.header.share.title": "您的商品",
      "wishlist.header.title": "您的精選商品",
      "wishlist.header.description":
        "我們為您挑選了這 {{count}} 件商品。盡情探索我們的獨特精湛工藝。",

      "wishlist.products.size": "尺寸",
      "wishlist.products.quantity": "數量",
      "wishlist.products.totalAmount": "總計",

      "product.fiels.reference": "Ref",

      "wishlist.footer.sa.title":
        "如果您需要更多資訊，我將竭誠為您服務。/n 祝您順心",
      "wishlist.footer.sa.callme": "致電給我",
      "wishlist.footer.sa.callme.withoutphone": "致電給我",
      "wishlist.footer.sa.emailme": "寄電子郵件給我",
      "wishlist.footer.sa.emailme.withoutemail": "寄電子郵件給我",
      "wishlist.footer.date.shareone": "在 {{date}} 分享",
      "wishlist.footer.mentions":
        "*供應情況和價格僅供參考，且根據所選的 Dior 精品店而可能有所不同。詳情請洽詢您的銷售顧問。",
    },
  },
  zh_CN: {
    common: {
      "notfound.header.title": "未找到页面",
      "notfound.header.description":
        "可能已不再提供产品。请直接联系您的销售顾问",

      "wishlist.header.share.title": "您的产品",
      "wishlist.header.title": "您的选择",
      "wishlist.header.description":
        "我已为您精心挑选了这些{{count}} 产品，为您打造不一样的自己。",

      "wishlist.products.size": "尺码",
      "wishlist.products.quantity": "数量",
      "wishlist.products.totalAmount": "总计",

      "product.fiels.reference": "货号",

      "wishlist.footer.sa.title": "如需更多信息，我很乐意随时为您服务。/n 祝好",
      "wishlist.footer.sa.callme": "与我电话联系",
      "wishlist.footer.sa.callme.withoutphone": "与我电话联系",
      "wishlist.footer.sa.emailme": "与我邮件联系",
      "wishlist.footer.sa.emailme.withoutemail": "与我邮件联系",
      "wishlist.footer.date.shareone": "于 {{date}} 分享",
      "wishlist.footer.mentions":
        " *产品是否有货以及价格仅作参考，可能根据所选的 DIOR 精品店而有所不同。欲了解更多详细信息，请联系您的销售顾问。",
    },
  },
  es: {
    common: {
      "notfound.header.title": "Página no encontrada",
      "notfound.header.description":
        "PPuede que los productos ya no estén disponibles. Póngase en contacto directamente con su asesor de ventas",

      "wishlist.header.share.title": "SU PRODUCTO",
      "wishlist.header.title": "SU SELECCIÓN",
      "wishlist.header.description":
        "He seleccionado estos {{count}} productos para usted. Déjese seducir por nuestro savoir-faire único.",

      "wishlist.products.size": "Talla",
      "wishlist.products.quantity": "Cantidad",
      "wishlist.products.totalAmount": "Total",

      "product.fiels.reference": "Ref",

      "wishlist.footer.sa.title":
        "Quedo a su entera disposición para proporcionarle información más detallada si la necesita. /n  Atentamente,",
      "wishlist.footer.sa.callme":
        "Deseo que se pongan en contacto conmigo por teléfono",
      "wishlist.footer.sa.callme.withoutphone":
        "Deseo que se pongan en contacto conmigo por teléfono",
      "wishlist.footer.sa.emailme":
        "Deseo que se pongan en contacto conmigo por correo electrónico",
      "wishlist.footer.sa.emailme.withoutemail":
        "Deseo que se pongan en contacto conmigo por correo electrónico",
      "wishlist.footer.date.shareone": "Compartido el {{date}}",
      "wishlist.footer.mentions":
        "* La disponibilidad y los precios son indicativos y pueden variar en función de la boutique Dior seleccionada. Póngase en contacto con su asesor de ventas para más información.",
    },
  },
  de: {
    common: {
      "notfound.header.title": "Seite nicht gefunden",
      "notfound.header.description":
        "Die Produkte sind möglicherweise nicht mehr verfügbar. Wenden Sie sich direkt an Ihre Verkaufsberaterin oder Ihren Verkaufsberater.",

      "wishlist.header.share.title": "IHR PRODUKT",
      "wishlist.header.title": "IHRE AUSWAHL",
      "wishlist.header.description":
        "Ich habe diese {{count}} Produkte für Sie ausgewählt. Lassen Sie sich von unserem außerordentlichen Know-how überzeugen.",

      "wishlist.products.size": "Größe",
      "wishlist.products.quantity": "Menge",
      "wishlist.products.totalAmount": "Gesamt",

      "product.fiels.reference": "Ref.",

      "wishlist.footer.sa.title":
        "Bei weiteren Fragen stehe ich Ihnen jederzeit gerne zur Verfügung. /n Mit freundlichen Grüßen",
      "wishlist.footer.sa.callme": "Anrufen",
      "wishlist.footer.sa.callme.withoutphone": "Telefon",
      "wishlist.footer.sa.emailme": "E-Mail senden",
      "wishlist.footer.sa.emailme.withoutemail": "E-Mail-Adresse",
      "wishlist.footer.date.shareone": "Geteilt am {{date}}",
      "wishlist.footer.mentions":
        "* Die Verfügbarkeit und die Preise sind nicht verbindlich und können je nach ausgewählter Dior Boutique variieren. Weitere Informationen erhalten Sie von Ihrer Verkaufsberaterin oder Ihrem Verkaufsberater.",
    },
  },
  it: {
    common: {
      "notfound.header.title": "Pagina non trovata",
      "notfound.header.description":
        "I prodotti potrebbero non essere più disponibili. Contatta direttamente il tuo Consulente di vendita",

      "wishlist.header.share.title": "IL TUO PRODOTTO",
      "wishlist.header.title": "LA TUA SELEZIONE",
      "wishlist.header.description":
        "Ho selezionato questi {{count}} prodotti per te. Lasciati affascinare dalle nostre esclusive creazioni.",

      "wishlist.products.size": "Misura",
      "wishlist.products.quantity": "Quantità",
      "wishlist.products.totalAmount": "Totale",

      "product.fiels.reference": "Rif",

      "wishlist.footer.sa.title":
        "Resto a tua completa disposizione qualora avessi bisogno di ulteriori informazioni. /n Cordiali saluti,",
      "wishlist.footer.sa.callme": "Chiamami",
      "wishlist.footer.sa.callme.withoutphone": "Chiamami",
      "wishlist.footer.sa.emailme": "Inviami un’e-mail",
      "wishlist.footer.sa.emailme.withoutemail": "Inviami un’e-mail",
      "wishlist.footer.date.shareone": "Condiviso il {{date}}",
      "wishlist.footer.mentions":
        "* Disponibilità e prezzi sono soltanto indicativi e possono variare a seconda della boutique Dior selezionata. Contatta il tuo Consulente di vendita per ulteriori informazioni.",
    },
  },
  nl: {
    common: {
      "notfound.header.title": "Pagina niet gevonden",
      "notfound.header.description":
        "Producten zijn mogelijk niet meer verkrijgbaar. Neem rechtstreeks contact op met uw verkoopadviseur",

      "wishlist.header.share.title": "UW PRODUCT",
      "wishlist.header.title": "UW KEUZE",
      "wishlist.header.description":
        "Ik heb deze {{count}} producten voor u. Laat u verleiden door onze unieke knowhow.",

      "wishlist.products.size": "Maat",
      "wishlist.products.quantity": "Hoeveelheid",
      "wishlist.products.totalAmount": "Totaal",

      "product.fiels.reference": "Ref",

      "wishlist.footer.sa.title":
        "Ik sta voor u klaar als u meer informatie nodig heeft. /n Met vriendelijke groet,",
      "wishlist.footer.sa.callme": "Bel me",
      "wishlist.footer.sa.callme.withoutphone": "Bel me",
      "wishlist.footer.sa.emailme": "Mail me",
      "wishlist.footer.sa.emailme.withoutemail": "Mail me",
      "wishlist.footer.date.shareone": "Gedeeld op {{date}}",
      "wishlist.footer.mentions":
        "* Beschikbaarheid en prijzen zijn slechts indicatief en kunnen variëren, afhankelijk van de geselecteerde Dior-boetiek. Neem contact op met uw verkoopadviseur voor meer informatie.",
    },
  },

  pt: {
    common: {
      "notfound.header.title": "Página não encontrada",
      "notfound.header.description":
        "É possível que os produtos não estejam mais disponíveis. Entre em contato diretamente com seu consultor de vendas",

      "wishlist.header.share.title": "SEU PRODUTO",
      "wishlist.header.title": "SUA SELEÇÃO",
      "wishlist.header.description":
        "Selecionei estes {{count}} produtos para você. Deixe que nosso savoir-faire exclusivo encante você.",

      "wishlist.products.size": "Tamanho",
      "wishlist.products.quantity": "Quantidade",
      "wishlist.products.totalAmount": "Total",

      "product.fiels.reference": "Ref",

      "wishlist.footer.sa.title":
        "Fico à sua disposição caso precise de mais informações. /n Atenciosamente,",
      "wishlist.footer.sa.callme": "Ligar",
      "wishlist.footer.sa.callme.withoutphone": "Ligar",
      "wishlist.footer.sa.emailme": "Enviar e-mail",
      "wishlist.footer.sa.emailme.withoutemail": "Enviar e-mail",
      "wishlist.footer.date.shareone": "Compartilhado em {{date}}",
      "wishlist.footer.mentions":
        "* A disponibilidade e os preços são meramente ilustrativos e variam dependendo principalmente da boutique Dior selecionada. Entre em contato com seu consultor de vendas para obter mais informações.",
    },
  },
  ro: {
    common: {
      "notfound.header.title": "Pagina nu a fost găsită",
      "notfound.header.description":
        "Este posibil ca produsele să numai fie disponibile. Contactați-vă direct consultantul în vânzări",

      "wishlist.header.share.title": "PRODUSUL DVS.",
      "wishlist.header.title": "SELECȚIA DVS.",
      "wishlist.header.description":
        "Am selectat aceste {{count}} produse pentru dvs. Lăsați-vă seduși de informațiile noastre unice.",

      "wishlist.products.size": "Mărime",
      "wishlist.products.quantity": "Cantitate",
      "wishlist.products.totalAmount": "Total",

      "product.fiels.reference": "Nr.",

      "wishlist.footer.sa.title":
        "Sunt la dispoziția dvs. dacă aveți nevoie de mai multe informații. /n Cu stimă,",
      "wishlist.footer.sa.callme": "Apelați-mă",
      "wishlist.footer.sa.callme.withoutphone": "Apelați-mă",
      "wishlist.footer.sa.emailme": "Trimiteți-mi e-mail",
      "wishlist.footer.sa.emailme.withoutemail": "Trimiteți-mi e-mail",
      "wishlist.footer.date.shareone": "Partajat pe data de {{date}}",
      "wishlist.footer.mentions":
        "* Disponibilitatea și prețurile sunt doar orientative și pot varia în funcție de magazinul Dior. Vă rugăm să vă contactați consultantul de vânzări pentru mai multe informații.",
    },
  },
  zh_HK: {
    common: {
      "notfound.header.title": "找不到頁面",
      "notfound.header.description": "商品可能不再供應。直接聯絡您的銷售顧問",

      "wishlist.header.share.title": "您的商品",
      "wishlist.header.title": "您的選擇",
      "wishlist.header.description":
        "我已為您挑選 {{count}} 件商品。盡情獲取我們獨特的專業知識。",

      "wishlist.products.size": "尺碼",
      "wishlist.products.quantity": "數量",
      "wishlist.products.totalAmount": "總計",

      "product.fiels.reference": "參考",

      "wishlist.footer.sa.title":
        "如果您需要進一步的資訊，我們隨時為您服務。/n 謹致，",
      "wishlist.footer.sa.callme": "致電給我",
      "wishlist.footer.sa.callme.withoutphone": "致電給我",
      "wishlist.footer.sa.emailme": "發送電子郵件給我",
      "wishlist.footer.sa.emailme.withoutemail": "發送電子郵件給我",
      "wishlist.footer.date.shareone": "已於 {{date}} 分享",
      "wishlist.footer.mentions":
        "* 庫存量和價格僅供參考，並可能會因所選的 Dior 精品店而有所不同。請聯絡您的銷售顧問了解詳情。",
    },
  },
  th: {
    common: {
      "notfound.header.title": "ไม่พบหน้าที่ค้นหา",
      "notfound.header.description":
        "อาจไม่มีผลิตภัณฑ์วางจำหน่ายอีกต่อไปแล้ว โปรดติดต่อผู้ให้คำปรึกษาฝ่ายขายของคุณโดยตรง",

      "wishlist.header.share.title": "ผลิตภัณฑ์ของคุณ",
      "wishlist.header.title": "ผลิตภัณฑ์ที่คุณเลือก",
      "wishlist.header.description":
        "คุณได้เลือกผลิตภัณฑ์ไว้ {{count}} รายการ เชิญดื่มด่ำไปกับความสุขด้วยความชำนาญเฉพาะทางของเราที่ไม่มีใครเหมือนกันได้เลย",

      "wishlist.products.size": "ขนาด",
      "wishlist.products.quantity": "ปริมาณ",
      "wishlist.products.totalAmount": "รวม",

      "product.fiels.reference": "ข้อมูลอ้างอิง",

      "wishlist.footer.sa.title":
        "ผม/ดิฉันพร้อมที่จะรับใช้คุณเสมอหากท่านต้องการข้อมูลใด ๆ เพิ่มเติม /n ขอแสดงความนับถือ",
      "wishlist.footer.sa.callme": "โทรศัพท์",
      "wishlist.footer.sa.callme.withoutphone": "โทรศัพท์",
      "wishlist.footer.sa.emailme": "อีเมล",
      "wishlist.footer.sa.emailme.withoutemail": "อีเมล",
      "wishlist.footer.date.shareone": "แชร์เมื่อ {{date}}",
      "wishlist.footer.mentions":
        "* ความพร้อมในการวางจำหน่ายกับราคาของผลิตภัณฑ์เป็นเพียงข้อมูลที่แสดงไว้เท่านั้น ซึ่งข้อมูลดังกล่าวอาจแตกต่างกันไปในร้านบูติกของ Dior แต่ละแห่ง โปรดติดต่อผู้ให้คำปรึกษาฝ่ายขายของคุณสำหรับข้อมูลเพิ่มเติม",
    },
  },
};

i18next
  .use(initReactI18next)
  .use(detector)
  .use(XHR)
  .init({
    resources: resources,
    lng: "en",
    fallbackLng: false,
    keySeparator: false,
    interpolation: { escapeValue: false },
    debug: false,
    ns: ["common"],
    defaultNS: ["common"],
    react: {
      wait: true,
    },
  });

export default i18next;
